import React, { useState } from "react";
import MainWrapper from "../../MainWrapper";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Details from "./Details";
import List from "./List";
import { useLocation } from "react-router-dom";

function Index() {
  const [details, setDetails] = useState(null);
  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const profit_coin = queryParams.get("profit_coin");
  const deposit_coin = queryParams.get("deposit_coin");
  const exchange = queryParams.get("exchange");
  const [showSideBar, setShowSideBar] = useState(false);

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  
  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "1", href: "/dashboard" }}
      />
      <Header setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
      {details ? (
        <Details setDetails={setDetails} details={details} />
      ) : (
        <List
          details={details}
          setDetails={setDetails}
          exchange={exchange}
          profit_coin={profit_coin}
          deposit_coin={deposit_coin}
        />
      )}
    </MainWrapper>
  );
}

export default Index;
