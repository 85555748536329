import React from "react";
import bitcoin from "../../../assets/icons/bitcoin.svg";
import green_arrow from "../../../assets/icons/Green_arrow.svg";
import green_path from "../../../assets/icons/path.svg";
import usdc from "../../../assets/icons/usd-coin.svg";
import tether from "../../../assets/icons/tether-usdt.svg";
import ethereum from "../../../assets/icons/ethereum.svg";
import solana from "../../../assets/icons/solana.svg";

export const USD_BOTS = ["CB003", "CB006", "CB009", "CB010", "CB011"];

const coins = [
  { name: "btc", value: bitcoin },
  { name: "eth", value: ethereum },
  { name: "sol", value: solana },
];

export const ColorCard = ({
  bg,
  titleColor,
  lightCircle,
  textLight,
  textDark,
  wallets,
  strategy,
  onClick,
  explore,
}) => {
  const disable_a_tag = strategy.isAvailable
    ? ""
    : "pointer-events-none opacity-50 cursor-not-allowed";

  const isAvailable = strategy.isAvailable;

  return (
    <div className={`sm:w-[555px] w-full sm:text-md text-[10px] `}>
      <div
        className={`border-8 rounded-2xl border-[#62688F] ${textLight} ${bg} py-1.5 px-2`}
      >
        <div className={`flex justify-between items-center px-1`}>
          <div
            onClick={onClick}
            className={`bg-primary px-2 rounded-full sm:w-[25%] whitespace-nowrap text-center py-1 uppercase my-1 font-extrabold cursor-pointer hover:scale-105 transition-transform duration-300 ${
              strategy.isAvailable ? "" : "pointer-events-none"
            } ${titleColor}`}
          >
            {explore
              ? explore
              : strategy.isAvailable
              ? "Activate bot"
              : "Not Available"}
          </div>
          <div className={`sm:text-5xl text-lg ${titleColor}  font-semibold`}>
            {strategy?.name || ""}
          </div>
        </div>

        <div className="flex mt-2 sm:mt-0">
          <div
            className={`flex flex-col justify-between w-[70%] gap-2 sm:gap-4`}
          >
            <div
              className={`flex ${
                isAvailable ? lightCircle : "bg-[#12182236]"
              } ${textLight} py-1 px-2 flex-col items-end sm:mx-1 gap-1 rounded-xl sm:p-4`}
            >
              <div className={`flex justify-between items-center w-full`}>
                <span className="sm:text-sm">Strategy direction</span>
                <div className="flex gap-2">
                  <span className=" sm:text-sm">{strategy.direction}</span>
                  <img
                    alt=""
                    className={`${
                      strategy.direction.includes("LONG")
                        ? ""
                        : "rotate-[180deg]"
                    } w-4 h-4  ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                    src={green_arrow}
                  />
                </div>
              </div>
              <div className={`flex justify-between items-center w-full`}>
                <span className="sm:text-sm">Deposit denomination:</span>
                <span className="uppercase sm:text-sm">
                  {strategy.deposit_coin}
                </span>
              </div>
              <div className={`flex justify-between items-center w-full`}>
                <span className="sm:text-sm">Profits are settled in:</span>
                <span className="uppercase sm:text-sm">
                  {strategy.profit_coin}
                </span>
              </div>
              <div className={`flex justify-between items-center w-full`}>
                <span className="sm:text-sm">Minimum deposit:</span>
                <span className="sm:text-sm">{strategy.minimum_deposit}</span>
              </div>
            </div>

            <div className="flex items-center gap-4 overflow-x-scroll">
              <div className={`flex flex-col`}>
                <span className="sm:text-sm whitespace-nowrap">
                  Market Type:
                </span>
                <div
                  className={`${
                    isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                  } ${textDark} flex items-center rounded-xl px-3 py-1 sm:text-sm $ font-semibold whitespace-nowrap`}
                >
                  {strategy.market_type}
                </div>
              </div>

              <div className={`flex flex-col`}>
                <span className="whitespace-nowrap sm:text-sm">
                  Leverage used:
                </span>
                <div
                  className={`${
                    isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                  } ${textDark} flex sm:text-sm items-center py-1 justify-center rounded-xl px-2 $ font-semibold`}
                >
                  {strategy.leverage}
                </div>
              </div>

              <div className={`flex flex-col`}>
                <span className="whitespace-nowrap sm:text-sm">
                  Safety net:
                </span>
                <div
                  className={`${
                    isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                  } ${textDark} whitespace-nowrap sm:text-sm flex justify-center py-1 items-center rounded-xl px-2 h-6 $ font-semibold`}
                >
                  {strategy.safety_net}
                </div>
              </div>
            </div>
            <div className={`flex justify-between items-center w-full `}>
              <span className="sm:text-sm">
                Avg. monthly compounded return:
              </span>
              <div
                className={`${
                  isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                } ${textDark} flex items-center rounded-xl px-2 h-6 sm:text-sm font-semibold`}
              >
                {strategy.return}
              </div>
            </div>
          </div>

          <div
            className={`${
              isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
            } ${textDark} w-[30%] rounded-xl mx-1 sm:mx-1.5 px-2 flex flex-col justify-center`}
          >
            <div className="flex items-center gap-4">
              <div className="flex gap-1">
                {strategy.deposit_coin === "usd" ? (
                  <div
                    className={` ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                  >
                    <img className="w-4 sm:w-6" src={tether} alt="coin_logo" />
                    <img
                      className="w-4 sm:w-6 sm:ml-4"
                      src={usdc}
                      alt="coin_logo"
                    />
                  </div>
                ) : (
                  <img
                    className={`sm:w-8 w-4 ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                    src={
                      coins.filter((e) => e.name === strategy.deposit_coin)[0]
                        .value
                    }
                    alt="coin_logo"
                  />
                )}
              </div>
              <span className={`sm:text-md text-[#d9d9d9a6]`}>Deposit</span>
            </div>

            <div className="flex justify-center p-4">
              <img
                src={green_path}
                className={`sm:w-14 w-10 ${onClick ? "" : "filter grayscale"}`}
                alt=""
              />
            </div>

            <div className="flex items-center justify-end gap-4">
              <span className={`text-[#d9d9d9a6] sm:text-md whitespace-nowrap`}>
                Profit in
              </span>
              <div className="flex gap-1">
                {strategy.profit_coin == "usd" ? (
                  <div
                    className={` ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                  >
                    <img className="sm:w-6" src={tether} alt="coin_logo" />
                    <img
                      className="w-4 ml-2 sm:w-6 sm:ml-4"
                      src={usdc}
                      alt="coin_logo"
                    />
                  </div>
                ) : (
                  <img
                    className={`sm:w-8 w-4 ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                    src={
                      coins.filter((e) => e.name === strategy.profit_coin)[0]
                        .value
                    }
                    alt="coin_logo"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <span className={`whitespace-nowrap sm:text-sm `}>
            Exchanges available:
          </span>

          <div
            className={`${
              isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
            } ${textDark} py-4 mt-2 px-8 mx-1 rounded-2xl grid grid-flow-col-dense gap-4 items-center justify-center`}
          >
            {wallets.map((src, i) => (
              <img
                key={`${i}`}
                src={src}
                alt=""
                className={`sm:w-12 w-8 ${
                  strategy.isAvailable ? "" : "filter grayscale"
                }`}
              />
            ))}
          </div>
        </div>

        <div className="flex items-center text-[13px]  underline justify-between mt-2 pr-8 py-2">
          <a
            href="/dashboard/data-room"
            target="_blank"
            className={`whitespace-nowrap   ${disable_a_tag}`}
          >
            Data Room
          </a>
          <a
            href="https://help.cryptobots.io/faq"
            target="_blank"
            className={`whitespace-nowrap ${disable_a_tag}`}
            rel="noreferrer"
          >
            faq
          </a>
          <a
            href="https://help.cryptobots.io/"
            target="_blank"
            className={`whitespace-nowrap ${disable_a_tag}`}
            rel="noreferrer"
          >
            help
          </a>
        </div>
      </div>
    </div>
  );
};
export const GreyCard = () => {
  return <div>Card</div>;
};
