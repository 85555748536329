import React, { useEffect, useState } from "react";
import MainWrapper from "../../MainWrapper";
import NavHeader from "../../NavHeader";
import Hero from "./Hero";
import Partners from "./Partners";
import How from "./How";
import Strategy from "./Strategy";
import Guide from "./Guide";
import Features from "./Features";
import Results from "./Results";
import Affliate from "./Affliate";
import Milestone from "./Milestone";
import Footer from "./Footer";
import logo from "../../../assets/images/logo.png";
import { useLocalStorage } from "../../../hooks";

// function Index() {
//   return (
//     <div className="relative flex flex-col items-center justify-center h-screen bg-primary font-jura">
//       <div className="absolute top-0 left-0 right-0">
//         <div className="px-24 pt-8">
//           <div className="flex items-center justify-between px-6 py-2 rounded-full bg-opacity-10 ">
//             <div className="flex items-center gap-4 opacity-100">
//               <img className="w-8" src={logo} alt="logo" />
//               <span className="text-xl font-semibold text-white">cryptobots.io</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="text-3xl font-bold text-white font-jura animate-fadeIn animate-bounce">Coming soon..</div>
//     </div>
//   );
// }

function Index() {
  const [_locale, _setLocale] = useLocalStorage("locale", "en");
  const [locale, setLocale] = useState(_locale);

  useEffect(() => {
    _setLocale(locale);
  }, [locale]);
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  return (
    <MainWrapper>
      <Hero locale={locale} setLocale={setLocale} />
      <Partners locale={locale} />
      <How locale={locale} />
      <Strategy locale={locale} />
      <Guide locale={locale} />
      <Features locale={locale} />
      <Affliate locale={locale} />
      <Milestone locale={locale} />
      <Footer locale={locale} />
    </MainWrapper>
  );
}

export default Index;
