import React, { useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import bitcoin from "../../assets/icons/bitcoin.svg";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";
import okx from "../../assets/icons/OKX.svg";
import kraken from "../../assets/icons/Kraken.svg";
import gate_io from "../../assets/icons/full-gate.svg";
import coinbase from "../../assets/icons/coinbase.svg";
import gemini from "../../assets/icons/gemini.svg";
import kucoin from "../../assets/icons/kucoin.svg";
import binance from "../../assets/icons/binance.svg";
import binance_us from "../../assets/icons/Binance-us.svg";
import bybit from "../../assets/icons/bybit.svg";
import { ColorCard } from "../Dashboard/BotActivate/CardSmall";
import { useLoading } from "../../hooks";
import { useMediaQuery } from "react-responsive";

export const btc_params = {
  logo: [bitcoin],
  denomination: "BTC",
  wallets: [binance, okx, gate_io, bybit],
};

export const eth_params = {
  logo: [ethereum],
  denomination: "ETH",
  wallets: [binance, okx, bybit],
};

export const sol_params = {
  logo: [solana],
  wallets: [binance, okx],
  denomination: "SOL",
};

export const usd_params = {
  logo: [usdc, tether],
  denomination: "USD",
  wallets: [
    binance,
    kraken,
    coinbase,
    gemini,
    bybit,
    okx,
    gate_io,
    kucoin,
    binance_us,
  ],
};

export const CB001 = {
  ...btc_params,
  bg: "",
  titleColor: "text-[#FB7800]",
  lightCircle: "",
  textLight: "",
  textDark: "",
  strategy: {
    name: "CB001",
    return: "3.0%+",
    direction: "BEAR",
    leverage: "N/A",
    minimum_deposit: "$1,000.00",
    maximum_deposit: "$4,000.00",
    market_type: "SPOT",
    deposit_coin: "btc",
    profit_coin: "btc",
    safety_net: "N/A",
    isAvailable: false,
  },
};

export const CB002 = {
  ...btc_params,
  bg: "bg-[#F7A600]",
  titleColor: "text-[#FB7800A6]",
  lightCircle: "bg-[#FA7800]",
  textLight: "text-[#505155]",
  textDark: "text-[#FA7800]",
  strategy: {
    name: "CB002",
    return: "2.0%+",
    direction: "LONG/SHORT",
    leverage: "1.0x to 1.5x",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "Inverse Derivatives",
    deposit_coin: "btc",
    profit_coin: "btc",
    safety_net: "-40% to -50%",
    isAvailable: true,
  },
};

export const CB003 = {
  ...btc_params,
  bg: "bg-[#FCB900]",
  titleColor: "text-[#FB7800A6]",
  lightCircle: "bg-[#FA7800]",
  textLight: "text-[#505155]",
  textDark: "text-[#FA7800]",
  strategy: {
    name: "CB003",
    return: "2%+",
    direction: "LONG/SHORT",
    leverage: "1.0x to 1.5x",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "Inverse Derivatives",
    deposit_coin: "btc",
    profit_coin: "usd",
    safety_net: "-40% to -50%",
    isAvailable: true,
  },
};

export const CB004 = {
  ...eth_params,
  titleColor: "text-[#468FDE]",
  strategy: {
    name: "CB004",
    return: "3.0%+",
    direction: "BEAR",
    leverage: "N/A",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "SPOT",
    deposit_coin: "eth",
    profit_coin: "eth",
    safety_net: "N/A",
    isAvailable: false,
  },
};

export const CB005 = {
  ...eth_params,
  bg: "bg-[#2775CA]",
  titleColor: "text-[#468FDE]",
  lightCircle: "bg-[#468FDE]",
  textLight: "text-[#FFFFFFA6]",
  textDark: "text-[#3D85C6]",
  strategy: {
    name: "CB005",
    return: "2.0%+",
    direction: "LONG/SHORT",
    leverage: "1.0x to 1.5x",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "Inverse Derivatives",
    deposit_coin: "eth",
    profit_coin: "eth",
    safety_net: "-40% to -50%",
    isAvailable: true,
  },
};

export const CB006 = {
  ...eth_params,
  bg: "bg-[#468FDE]",
  titleColor: "text-[#2775CA]",
  lightCircle: "bg-[#2775CA]",
  textLight: "text-[#FFFFFFA6]",
  textDark: "text-[#3D85C6]",
  strategy: {
    name: "CB006",
    return: "3.0%+",
    direction: "LONG/SHORT",
    leverage: "1.0x to 1.5x",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "Linear Derivatives",
    deposit_coin: "eth",
    profit_coin: "usd",
    safety_net: "-40% to -50%",
    isAvailable: true,
  },
};

export const CB007 = {
  ...sol_params,
  titleColor: "text-[#C69ACE]",
  strategy: {
    name: "CB007",
    return: "6.0%+",
    direction: "SPOT",
    market_type: "BEAR",
    leverage: "N/A",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    deposit_coin: "sol",
    profit_coin: "sol",
    safety_net: "-40% to -50%",
    isAvailable: false,
  },
};

export const CB008 = {
  ...sol_params,
  bg: "bg-[#9864E37F]",
  titleColor: "text-[#C69ACE]",
  lightCircle: "bg-[#2D2893]",
  textLight: "text-[#FFFFFFA6]",
  textDark: "text-[#C69ACE]",
  strategy: {
    name: "CB008",
    return: "4.0%+",
    direction: "LONG/SHORT",
    leverage: "1.0x to 1.5x",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "Inverse Derivatives",
    deposit_coin: "sol",
    profit_coin: "sol",
    safety_net: "-40% to -50%",
    isAvailable: true,
  },
};

export const CB009 = {
  ...sol_params,
  bg: "bg-[#BD95F87F]",
  titleColor: "text-[#C69ACE]",
  lightCircle: "bg-[#2D2893]",
  textLight: "text-[#FFFFFFA6]",
  textDark: "text-[#C69ACE]",
  strategy: {
    name: "CB009",
    return: "4.0%+",
    direction: "LONG/SHORT",
    leverage: "1.0x to 1.5x",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "Linear Derivatives",
    deposit_coin: "sol",
    profit_coin: "usd",
    safety_net: "-40% to -50%",
    isAvailable: true,
  },
};

export const CB010 = {
  ...usd_params,
  bg: "bg-[#02BE89]",
  titleColor: "text-[#64FFDA]",
  lightCircle: "bg-[#0C8462]",
  textLight: "text-[#292C3C]",
  textDark: "text-[#00FFA3]",
  strategy: {
    name: "CB010",
    return: "2.0%+",
    direction: "BULL",
    leverage: "N/A",
    minimum_deposit: "$10,000",
    maximum_deposit: "$4,000,000",
    market_type: "SPOT(BTC/INDEX)",
    safety_net: "-40% to -50%",
    deposit_coin: "usd",
    profit_coin: "usd",
    isAvailable: true,
  },
};

export const CB011 = {
  ...usd_params,
  titleColor: "text-[#00FFA3]",
  strategy: {
    name: "CB011",
    return: "3.0%+",
    direction: "SHORT",
    leverage: "1x",
    minimum_deposit: "1 BTC in USD",
    maximum_deposit: "$4,000,000",
    market_type: "SPOT(BTC/INDEX)",
    safety_net: "100%",
    deposit_coin: "usd",
    profit_coin: "usd",
    isAvailable: false,
  },
};

// export const CB012 = {
//   ...usd_params,
//   bg: "bg-[#02BE89]",
//   titleColor: "text-[#64FFDA]",
//   lightCircle: "bg-[#0C8462]",
//   textLight: "text-[#292C3C]",
//   textDark: "text-[#00FFA3]",
//   strategy: {
//     name: "CB012",
//     return: "8.0%",
//     direction: "BULL",
//     leverage: "N/A",
//     minimum_deposit: "$30,000",
//     maximum_deposit: "$4,000,000",
//     market_type: "SPOT",
//     safety_net: "100%",
//     deposit_coin: "usd",
//     profit_coin: "usd",
//     isAvailable: true,
//   },
// };

function Main({ setDetails, showModal, setShowModal, setType }) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <>
      <Wrapper className={""}>
        <div className="max-h-full">
          <div className="px-4 pt-16 text-lg">
            Please select the strategy you would like to explore (no bear market{" "} strategies available at the moment):
          </div>

          <div className="bg-[#292C3C] h-screen sm:h-[90vh] overflow-y-scroll rounded-[50px] lg:mt-5 px-5 py-5 mr-10 grid grid-rows-4 gap-10 ">
            <div className="flex flex-wrap items-center w-full h-auto gap-8 ">
              <ColorCard
                {...CB001}
                setShowModal={setShowModal}
                showModal={showModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB001);
                    setType("chart");
                  } else {
                    setDetails(CB001);
                  }
                }}
              />
              <ColorCard
                {...CB002}
                setShowModal={setShowModal}
                showModal={showModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB002);
                    setType("chart");
                  } else {
                    setDetails(CB002);
                  }
                }}
              />
              <ColorCard
                {...CB003}
                setShowModal={setShowModal}
                showModal={showModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB003);
                    setType("chart");
                  } else {
                    setDetails(CB003);
                  }
                }}
              />
              <ColorCard
                {...CB004}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB004);
                    setType("chart");
                  } else {
                    setDetails(CB004);
                  }
                }}
              />

              <ColorCard
                {...CB005}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB005);
                    setType("chart");
                  } else {
                    setDetails(CB005);
                  }
                }}
              />
              <ColorCard
                {...CB006}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB006);
                    setType("chart");
                  } else {
                    setDetails(CB006);
                  }
                }}
              />
              <ColorCard
                {...CB007}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB007);
                    setType("chart");
                  } else {
                    setDetails(CB007);
                  }
                }}
              />
              <ColorCard
                {...CB008}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB008);
                    setType("chart");
                  } else {
                    setDetails(CB008);
                  }
                }}
              />

              <ColorCard
                {...CB009}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB009);
                    setType("chart");
                  } else {
                    setDetails(CB009);
                  }
                }}
              />
              <ColorCard
                {...CB010}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB010);
                    setType("chart");
                  } else {
                    setDetails(CB010);
                  }
                }}
              />
              <ColorCard
                {...CB011}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setDetails(CB011);
                    setType("chart");
                  } else {
                    setDetails(CB011);
                  }
                }}
              />
              {/* <ColorCard
                {...CB012}
                showModal={showModal}
                setShowModal={setShowModal}
                onClick={() => {
                  if (isTabletOrMobile) {
                    setType("chart");
                  } else {
                    setDetails(CB012);
                  }
                }}
              /> */}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default Main;
