import React, { useEffect, useState } from "react";
import Wrapper from "./DashboardWrapper";
import avatar from "../../assets/icons/profile-icon.png";
import { useAppContext } from "../../Context";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { Decode, SignIn, SignInSocial, SignUp } from "../../API";
import { confirmSignIn } from "../../utilities";
import google_login from "../../assets/images/google_login.svg";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { GoogleLogin } from "@react-oauth/google";
function Main() {
  const [user, setUser] = useLocalStorage("user", {});
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailSignup, setEmailSignup] = useState("");
  const [passwordSignup, setPasswordSignup] = useState("");
  const [confirmPasswordSignup, setConfirmPasswordSignup] = useState("");

  // Access location object using useLocation hook
  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const referrer = queryParams.get("referrer");

  const loginWithGoogle = async (accessToken) => {
    console.log(accessToken, "actk");
    startLoading();
    const decode = await Decode({ accessToken });
    let result = decode?.result;
    if (result?.email) {
      const signin = await SignInSocial({
        name: `${result.given_name} ${result.family_name}`,
        email: result.email,
        // password: accessToken,
      });
      console.log(signin, "signin");
      if (signin?.success) {
        toast.success(signin.message, {
          position: "top-center",
        });
        setAuthToken(signin.authToken);
        setUser(signin.user);
        window.open("/dashboard/select-bot", "_self");
      } else {
        toast.error(signin?.message || "Error occured", {
          position: "top-center",
        });
      }
    } else {
      toast.error("Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const handleSignup = async () => {
    startLoading();
    const res = await SignUp({
      email: emailSignup,
      password: passwordSignup,
      name,
      referrer: referrer || "",
    });
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 2000);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res.message, { position: "top-center" });
      // console.log(res.message)
    }
    stopLoading();
  };

  const handleSignupValidation = () => {
    // console.log(name, emailSignup, passwordSignup, confirmPasswordSignup);
    // console.log("email: ",emailSignup)
    if (!name) {
      toast.error("Name required", { position: "top-center" });
      return;
    }
    if (!emailSignup) {
      toast.error("Email required ", { position: "top-center" });
      return;
    }

    if (!passwordSignup) {
      toast.error("Password required", { position: "top-center" });
      return;
    }
    if (!confirmPasswordSignup) {
      toast.error("Confirm Password required", { position: "top-center" });
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password must match Confirm Password", {
        position: "top-center",
      });
      return;
    }
    console.log(name, emailSignup, passwordSignup, confirmPasswordSignup);
    handleSignup();
  };

  const handleLogin = async () => {
    startLoading();
    const res = await SignIn({ email, password });
    if (res?.success) {
      setAuthToken(res.authToken);
      setUser(res.user);
      window.open("/dashboard/select-bot", "_self");
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  const handleValidation = () => {
    if (!email) {
      toast.error("Email required", { position: "top-center" });
      return;
    }
    if (!password) {
      toast.error("Password required", { position: "top-center" });
      return;
    }
    handleLogin();
  };

  useEffect(() => {
    // if (authToken)
    //window.open("/dashboard/select-bot", "_self");
    // else {
    // window.open("/dashboard", "_self");
    // }
  }, []);

  return (
    <Wrapper className={"px-8 py-8 rounded-xl relative "}>
      <Loader isLoading={isLoading} />

      <div className="p-0 sm:p-20 bg-[#292C3C] rounded-[50px] lg:mt-20 flex flex-col lg:gap-20  lg:justify-center sm:flex-row sm:justify-between overflow-x-hidden  sm:mt-0 mr-20">
        <img
          className="w-10 ml-4 rounded-full sm:hidden sm:ml-0"
          src={logo}
          alt="logo"
        />
        {/* Sign up */}
        <div className="flex flex-col gap-4 mt-6 sm:mt-16">
          <span className="px-2 text-xl font-bold">New Here?</span>
          <span className="px-2 text-gray-500 text-md">
            Create an account to get started and start making profits today!
          </span>
          <input
            placeholder="Enter name"
            className="px-2 py-1 bg-transparent focus:outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            placeholder="Enter email"
            className="px-2 py-1 bg-transparent focus:outline-none"
            value={emailSignup}
            onChange={(e) => setEmailSignup(e.target.value)}
          />
          <input
            placeholder="Enter password"
            className="px-2 py-1 bg-transparent focus:outline-none"
            value={passwordSignup}
            type="password"
            onChange={(e) => setPasswordSignup(e.target.value)}
          />
          <input
            placeholder="Re-enter password"
            className="px-2 py-1 bg-transparent focus:outline-none"
            value={confirmPasswordSignup}
            type="password"
            onChange={(e) => setConfirmPasswordSignup(e.target.value)}
          />
          <div className="flex items-center justify-between gap-8 mt-4 sm:mt-12">
            <div className="flex items-center gap-8">
              <div
                onClick={handleSignupValidation}
                className="flex items-center px-4 py-2 rounded-full cursor-pointer bg-green-dark text-md"
              >
                Sign up
              </div>
            </div>
            <div className="cursor-pointer ">
              <GoogleLogin
                onSuccess={(e) => loginWithGoogle(e.credential)}
                onError={(e) =>
                  toast.error(`Login failed. ${e}`, {
                    position: "top-center",
                  })
                }
              />
            </div>
          </div>
        </div>

        {/* {Border line} */}
        <div className="flex flex-col items-center justify-center gap-12 mt-12 sm:mt-0">
          <img
            className="hidden rounded-full w-14 sm:block"
            src={logo}
            alt="logo"
          />
          <div className="sm:border-r-[0.5px] border-b-[0.5px] sm:border-b-0 w-full sm:w-0 sm:h-full" />
        </div>

        {/* Login */}
        <div className="flex flex-col gap-4 mt-12 sm:mt-16">
          <span className="px-2 text-xl font-bold">Welcome back</span>
          <span className="px-2 text-gray-500 text-md">
            Please log in to access your account.
          </span>
          <input
            placeholder="Enter email"
            className="px-2 py-1 bg-transparent focus:outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="Enter password"
            className="px-2 py-1 bg-transparent focus:outline-none"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex items-center justify-between gap-8 mt-4 sm:mt-12">
            <div className="flex items-center gap-8">
              <div
                onClick={handleValidation}
                className="flex items-center px-4 py-2 rounded-full cursor-pointer bg-green-dark text-md"
              >
                Login
              </div>
            </div>
            <div
              onClick={() => window.open("/dashboard/forgotPassword", "_self")}
              className="underline cursor-pointer"
            >
              Forgot password?
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Main;
