import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import Header from "../Dashboard/Header";
import { useLoading, useLocalStorage } from "../../hooks";
import bitcoin from "../../assets/icons/bitcoin.svg";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";
import copy_icon from "../../assets/icons/copy.png";
import Sidebar from "../Dashboard/Sidebar";
import MainWrapper from "../MainWrapper";
import { toast } from "react-toastify";
import { FetchRewards, apiUrl } from "../../API";
import { confirmSignIn, formatDate } from "../../utilities";

const style_map = {
  btc: "text-[#F7931A]",
  eth: "",
  sol: "text-[#DC1FFF]",
  usd: "text-[#00FFA3]",
};

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error=()=>{};
}

export function WithdrawBtn({ href, style, coin, onClick }) {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
        else {
          window.open(href, "_self");
        }
      }}
      className={`bg-[#2775CA] px-6 rounded-2xl py-1 cursor-pointer ${style} ${style_map[coin]}`}
    >
      withdraw
    </div>
  );
}

function Main() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [eth_balance, seteth_balance] = useState(0);
  const [btc_balance, setbtc_balance] = useState(0);
  const [usd_balance, setusd_balance] = useState(0);
  const [sol_balance, setsol_balance] = useState(0);
  const [rewards, setRewards] = useState([]);
  const [user] = useLocalStorage("user", {});
  const [referralLink, setreferralLink] = useState(
    `${apiUrl}/signup/referrer=${user._id}`
  );

  const fetchRewards = async () => {
    startLoading();
    const res = await FetchRewards(authToken);
    if (res?.success) {
      setRewards(res.rewards);
      seteth_balance(res.eth_balance);
      setbtc_balance(res.btc_balance);
      setusd_balance(res.usd_balance);
      setsol_balance(res.sol_balance);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res?.message || "Unable to fetch invoices", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 3000);
    } else {
      fetchRewards();
    }
  }, []);

  const copyTextToClipboard = (value, feedback) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.success(feedback + " copied", {
          position: "top-center",
        });
        // Optionally, you can provide feedback to the user that the text has been copied
      })
      .catch((error) => {
        console.error("Error copying text:", error);
        // Handle any errors that may occur during copying
      });
  };

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "4", href: "/dashboard/affliate" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper className={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div className="flex flex-col gap-8 mt-16">
          <div className="bg-[#151924] py-8 px-6 rounded-xl flex justify-between items-center">
            <span className="w-[80%] overflow-hidden">
              Your Referral link is: {referralLink}
            </span>
            <img
              onClick={() => copyTextToClipboard(referralLink, "Link")}
              src={copy_icon}
              className="w-6 cursor-pointer"
            />
          </div>

          <div className="bg-[#151924] py-8 px-6 rounded-xl">
            <div className="mb-8 font-semibold">Available Affliate Rewards</div>

            <div className="flex flex-wrap items-center justify-between gap-10 sm:px-8">
              <div className="flex flex-col items-center justify-center gap-4">
                <img src={bitcoin} className="w-8 sm:w-10" />
                <span>{btc_balance} BTC</span>
                <WithdrawBtn
                  coin={"btc"}
                  href={`/dashboard/withdraw?coin=btc&bal=${btc_balance}`}
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-4">
                <img src={ethereum} className="w-8 sm:w-10" />
                <span>{eth_balance} ETH</span>
                <WithdrawBtn
                  coin={"eth"}
                  href={`/dashboard/withdraw?coin=eth&bal=${eth_balance}`}
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-4">
                <img src={solana} className="w-8 sm:w-10" />
                <span>{sol_balance} SOL</span>
                <WithdrawBtn
                  coin={"sol"}
                  href={`/dashboard/withdraw?coin=sol&bal=${sol_balance}`}
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex gap-2 sm:gap-4">
                  <img src={tether} className="w-8 sm:w-10" />
                  <img src={usdc} className="w-8 sm:w-10" />
                </div>
                <span>{usd_balance} USD</span>
                <WithdrawBtn
                  coin={"usd"}
                  href={`/dashboard/withdraw?coin=usd&bal=${usd_balance}`}
                />
              </div>
            </div>
          </div>

          <div className="bg-[#151924] py-14 px-6 rounded-xl">
            <div className="mb-4 font-semibold sm:mb-8">Rewards History</div>
            <div className="sm:mb-8 mb-4 sm:w-[60%] sm:text-md">
              Every time one of your referrals pays their profit share of the
              month, 25% of it gets automatically credited in your account and
              settled in the currency they paid with, for you to withdraw
              immediately.
            </div>

            <div className="overflow-scroll">
              <table className="w-full rounded-lg  bg-primary">
                <thead>
                  <tr className="border-b-[0.5px] border-[#fff] whitespace-nowrap">
                    <th className="px-8 py-4 text-left">Email</th>
                    <th className="px-8 py-4 text-left">Reward</th>
                    <th className="px-8 py-4 text-left">Date</th>
                  </tr>
                </thead>

                <tbody>
                  {rewards.length > 0 ? (
                    rewards.map((item) => (
                      <tr className="whitespace-nowrap" key={item}>
                        <td className="px-8 py-4">{item.user}</td>
                        <td className="px-8 py-4">
                          {item.amount} {item.currency}
                        </td>
                        <td className="px-8 py-4">
                          {formatDate(item.createdAt, "MMM DD, YYYY")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={8}
                        className="py-12 mt-20 font-semibold text-center opacity-50 sm:text-lg font-public-sans"
                      >
                        Nothing is here yet!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
