import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { FetchNotifications } from "../../API";
import MainWrapper from "../MainWrapper";
import Sidebar from "../Dashboard/Sidebar";
import Header from "../Dashboard/Header";

import { confirmSignIn, formatDate } from "../../utilities";

function Index() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [notifications, setNotifications] = useState([]);

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  
  const fetchNotifications = async () => {
    startLoading();
    const res = await FetchNotifications(authToken);
    console.log(res);
    if (res?.success) {
      setNotifications(res.notifications);
    } else {
      confirmSignIn(res?.message, window, setAuthToken);
      //toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "", href: "/dashboard" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper className={"sm:px-8 px-4 py-8 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div className="flex items-center gap-4 cursor-pointer w-[15%] py-2 mt-16 sm:mt-0">
          <span className="text-lg font-bold whitespace-nowrap">
            Notifications
          </span>
        </div>

        <div className="flex justify-between gap-2">
          <div className="bg-[#151924] sm:w-[80%] w-full py-8 px-6 rounded-xl flex flex-col gap-6 text-gray-400 mt-4">
            {notifications.length > 0 ? (
              notifications.map((item) => (
                <div
                  key={item._id}
                  className="border-b-[0.5px] py-2 border-border flex flex-col"
                >
                  <span className="">{item.message}</span>
                  <span className="text-gray-600">
                    {formatDate(item.createdAt, "MMM DD, YYYY. h:mm a")}
                  </span>
                </div>
              ))
            ) : (
              <div className="py-12 mt-8 font-semibold text-center opacity-50 sm:text-lg font-public-sans">
                Nothing is here yet!
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Index;
