import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";

function CenterModal({ setShowModal, showModal, Data }) {
  if (!showModal) return <></>;
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }

  return (
    <div className="fixed z-50 flex items-center justify-center h-screen min-w-full rounded-md bg-black/80 sm:py-6">
      <div className="bg-primary opacity-100 sm:min-w-[600px] rounded-md px-6 sm:px-8 sm:py-4 py-12 sm:h-[80%]">
        <div
          onClick={() => setShowModal(!showModal)}
          className="flex justify-end text-xl font-bold text-gray-600 cursor-pointer"
        >
          <IoMdClose className="w-8 h-8" />
        </div>

        <div className="flex flex-col items-center justify-center mt-8 text-sm font-light text-grey-700">
          {Data ? Data : ""}
        </div>
      </div>
    </div>
  );
}

export default CenterModal;
