import React from "react";

function MainWrapper({ children, style }) {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  return (
    <main
      className={`bg-primary text-sm font-jura sm:text-lg min-h-screen ${style} text-white`}
    >
      {children}
    </main>
  );
}

export default MainWrapper;
