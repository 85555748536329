export const apiUrl = "https://cbot-be-1.onrender.com";

    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
      console.info = () => {};
      console.warn = () => {};
      console.error=()=>{};
    }

export const SignIn = async (body) => {
  return await fetch(apiUrl + `/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UploadFile = async (body, authToken) => {
  return await fetch(apiUrl + `/upload-file`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
    body,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const SignInSocial = async (body) => {
  console.log(apiUrl, "apiUrl");
  return await fetch(apiUrl + `/login-social`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateProfile = async (body, authToken) => {
  return await fetch(apiUrl + `/update-profile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const SignUp = async (body) => {
  return await fetch(apiUrl + `/sign-up`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const ForgotPassword = async (body) => {
  return await fetch(apiUrl + `/forgot-password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const ChangePassword = async (body) => {
  return await fetch(apiUrl + `/change-password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const VerifyEmail = async (body) => {
  return await fetch(apiUrl + `/verify-email`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpgradePlan = async (body) => {
  return await fetch(apiUrl + `/upgrade_plan`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUser = async (authToken) => {
  return await fetch(apiUrl + `/fetch-user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchNotifications = async (authToken) => {
  return await fetch(apiUrl + `/fetch-notifications`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchHistogramData = async (_id, authToken) => {
  return await fetch(apiUrl + `/fetch-histogram-data?_id=${_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchStrategy = async ({ strategy, year, month }) => {
  return await fetch(
    apiUrl + `/fetch-strategy?strategy=${strategy}&year=${year}&month=${month}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: `Bearer ${authToken}`,
      },
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchInvoices = async (authToken) => {
  return await fetch(apiUrl + `/fetch-invoices`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchRewards = async (authToken) => {
  return await fetch(apiUrl + `/fetch-rewards`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBots = async (authToken) => {
  return await fetch(apiUrl + `/fetch-bots`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBotStats = async (authToken, bot_id) => {
  return await fetch(apiUrl + `/fetch-bot-stats?_id=${bot_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBotProfits = async (authToken, bot_id) => {
  return await fetch(apiUrl + `/fetch-bot-profits?_id=${bot_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
export const FetchBotDeals = async (authToken, bot_id) => {
  return await fetch(apiUrl + `/fetch-bot-deals?_id=${bot_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchIp = async () => {
  return await fetch(apiUrl + `/fetch-ip`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchReferral = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-referral`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchTransactions = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-transactions`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const Withdraw = async (body, authToken) => {
  return await fetch(apiUrl + `/withdraw`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const SendOTP = async (authToken) => {
  return await fetch(apiUrl + `/send-otp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const CreateBot = async (body, authToken) => {
  return await fetch(apiUrl + `/create-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const DeleteAccount = async (authToken) => {
  return await fetch(apiUrl + `/delete-account`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateInvoice = async (body) => {
  return await fetch(apiUrl + `/update-invoice`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateBot = async (body, authToken) => {
  return await fetch(apiUrl + `/update-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const Decode = async ({ accessToken }) => {
  return await fetch(apiUrl + `/decode-payload?accessToken=${accessToken}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
