import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Main from "./Main";
import MainWrapper from "../MainWrapper";

function Index() {
  const [showSideBar, setShowSideBar] = useState(false);
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "1", href: "/dashboard" }}
      />
      <Header setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
      <Main />
    </MainWrapper>
  );
}

export default Index;
