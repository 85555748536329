import React, { useEffect, useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Header from "../Dashboard/Header";
import MainWrapper from "../MainWrapper";
import Wrapper from "../Dashboard/DashboardWrapper";
import { AT, API, DR, EXCH, FAQ, STRA } from "../../assets/icons/index";
import vidEngIntro from "../../assets/CBIO Animation Videos/Intro to Cryptobots.io.mp4"
import vidEsIntro from "../../assets/CBIO Animation Videos Spanish/Intro to Cryptobots.io Spanish.mp4"

import vidEngStart from "../../assets/CBIO Animation Videos/Getting started.mp4"
import vidEsStart from "../../assets/CBIO Animation Videos Spanish/Getting Started Spanish.mp4"

import vidEngAffiliate from "../../assets/CBIO Animation Videos/Affiliate Prog.mp4"
import vidEsAffiliate from "../../assets/CBIO Animation Videos Spanish/Affiliate Program Spanish.mp4"
import { useLocalStorage } from "../../hooks";

const data = [
  { icon: <FAQ />, description: "FAQ", ref: "https://help.cryptobots.io/faq" },
  { icon: <STRA />, description: "Strategies", ref: "https://help.cryptobots.io/strategies" },
  { icon: <EXCH />, description: "Exchanges", ref: "https://help.cryptobots.io/exchanges" },
  { icon: <DR />, description: "Data Room", ref: "https://help.cryptobots.io/data-room" },
  { icon: <AT />, description: "Automated Trading", ref: "https://help.cryptobots.io/automated-trading" },
  { icon: <API />, description: "API", ref: "https://help.cryptobots.io/api" },
];

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error=()=>{};
}

function Main() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [_locale, _setLocale] = useLocalStorage("locale", "en");
  const [locale, setLocale] = useState(_locale);

  useEffect(() => {
    _setLocale(locale);
  }, [locale]);
  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "", href: "/dashboard/help" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

      <Wrapper className={"w-auto px-6 py-6 rounded-md relative "}>
        <div className="flex flex-col w-full sm:w-fit mt-16 gap-8 md:mt-20 sm:mt-0 bg-[#292C3C] p-clamp-padding-help rounded-[50px] mr-20">
          <div className="flex flex-col gap-3 text-lg opacity-85">
            <span className="text-2xl font-bold">
              Need assistance? You've come to the right place!
            </span>
            There are two convenient ways to get help from cryptobots.io
            <span className="font-bold">1. Knowledge Base:</span>
            Our comprehensive knowledge base contains detailed information about
            our services, features, and more. Simply follow this link,
            <span>
              <a
                rel="noreferrer"
                target="_blank"
                className="text-blue-600 underline"
                href="https://help.cryptobots.io"
              >
                help.cryptobots.io
              </a>{" "}
              to access it.
            </span>
            <br />
            <span className="font-bold">2. Chat Support:</span>
            If you need immediate assistance, you can use our chat box.
            <br />
            You have the option to interact with our AI, which is well trained
            with all relevant information and is ready to help you with any
            questions or issues related to our service.
            <br />
            Alternatively, you can connect directly with our support team
            through the chat for personalized assistance.
            <br /> <br />
            We are here and ready to assist you!
          </div>
        </div>
        <div className="py-10 px-clamp-padding-help">
          <div className="mb-10 text-lg">
            Check out our video guides on how to navigate through cryptbots.io
          </div>

          <div className="flex flex-col-reverse items-center w-full gap-10 lg:flex-row">
            <div className="flex flex-wrap gap-20 w-fit">
              <div className="">
                <p className="mb-5">All about crypbots.io</p>
                <div className="bg-[#ffffff58] w-[350px] h-[167px] relative">
                  <video
                    src={locale === 'es' ? vidEsIntro : vidEngIntro}
                    controls
                    className="absolute inset-0 object-cover w-full h-full"
                  />
                </div>
              </div>
              <div className="">
                <p className="mb-5">Step By Step to get started</p>
                <div className="bg-[#ffffff58] w-[350px] h-[167px] relative">
                  <video
                    src={locale === 'es' ? vidEsStart : vidEngStart}
                    controls
                    className="absolute inset-0 object-cover w-full h-full"
                  />
                </div>
              </div>
              <div className="">
                <p className="mb-5">Affiliate Program</p>
                <div className="bg-[#ffffff58] w-[350px] h-[167px] relative">
                  <video
                      src={locale === 'es' ? vidEsAffiliate : vidEngAffiliate}
                      controls
                      className="absolute inset-0 object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>

            {/* <div className="border-2 h-[350px]"></div> */}

            <div className="flex flex-row flex-wrap w-full gap-2 lg:w-1/3 lg:gap-10 lg:flex-col">
              {data.map((item, index) => (
                <a
                  href={item.ref}
                  target="_blank"
                  key={index}
                  className="flex items-center w-1/2 gap-4 mb-5 cursor-pointer lg:mb-0 lg:w-full"
                >
                  {item.icon}
                  <span>{item.description}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
