import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import user_icon from "../../assets/icons/user-icon.png";
import chevron_right from "../../assets/icons/chevron-right.png";
import { useAppContext } from "../../Context";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { DeleteAccount, SignIn } from "../../API";
import MainWrapper from "../MainWrapper";
import Sidebar from "../Dashboard/Sidebar";
import Header from "../Dashboard/Header";
import { useLocation } from "react-router-dom";
import { confirmSignIn } from "../../utilities";
import { googleLogout } from "@react-oauth/google";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AccountSettings from "./Account";

function Profile() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [user, setUser] = useLocalStorage("user", {});
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [profilePercentage, setProfilePercentage] = useState(60);
  const [activeTab, setActiveTab] = useState("");

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }

  const handleDeletion = async () => {
    startLoading();
    const res = await DeleteAccount(authToken);
    if (res?.success) {
      setAuthToken(res.authToken);
      window.open("/dashboard", "_self");
      setUser(res.user);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  useEffect(() => {
    if (user.avatar) {
      setProfilePercentage(10);
    }
  }, []);

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "", href: "/dashboard" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper className={"sm:px-8 px-4 py-8 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div className="flex items-center gap-4 cursor-pointer w-[15%] py-2 mt-16 sm:mt-0">
          <span className="text-lg font-bold whitespace-nowrap">Settings</span>
        </div>

        <div className="flex flex-col justify-between gap-8 sm:flex-row sm:gap-2">
          <div className="bg-[#151924] sm:w-[40%] py-8 sm:px-6 px-2 rounded-xl flex flex-col gap-6 text-gray-400 mt-4">
            {profilePercentage < 100 && (
              <div className="bg-[#3D85C6] py-4 px-4 gap-4 flex flex-col rounded-lg">
                <div className="flex items-center gap-2">
                  <div style={{ width: 80, height: 80 }}>
                    <CircularProgressbar
                      styles={buildStyles({
                        textColor: "#fff",
                        trailColor: "#5F9CD3",
                        pathColor: "#fff",
                      })}
                      value={profilePercentage}
                      text={`${profilePercentage}%`}
                    />
                  </div>

                  <div className="flex flex-col text-white">
                    <span className="font-bold">Profile information</span>
                    <span className="text-gray-200 text-md">
                      Complete your profile to unlock all features
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => setActiveTab("account_settings")}
                  className="bg-white font-semibold cursor-pointer rounded-full text-center py-2 text-[#3D85C6] text-md"
                >
                  Complete My Profile
                </div>
              </div>
            )}

            <div
              onClick={() => setActiveTab("account_settings")}
              className="flex items-center justify-between mt-6 cursor-pointer"
            >
              <div className="flex items-center gap-4 ">
                <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-primary">
                  <img src={user_icon} className="w-6" />
                </div>

                <div className="flex flex-col text-white">
                  <span className="font-bold">Account Settings</span>
                  <span className="text-gray-400 text-md">
                    Personal information, Email
                  </span>
                </div>
              </div>
              <div className="opacity-60">
                <img src={chevron_right} className="w-4" />
              </div>
            </div>
          </div>
          {activeTab == "account_settings" && (
            <AccountSettings setActiveTab={setActiveTab} />
          )}
        </div>

        <div className="flex items-center justify-between mt-12">
          <div
            onClick={() => {
              setAuthToken("");
              googleLogout();
              window.open("/dashboard", "_self");
            }}
            className="cursor-pointer text-red "
          >
            SIGN OUT
          </div>
          <div onClick={handleDeletion} className="cursor-pointer text-red ">
            DELETE ACCOUNT REQUEST
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Profile;
