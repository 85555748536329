import React, { useEffect, useState } from "react";
import Active from "./Active";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import TradeInfo from "./TradeInfo";
import Stats from "./Stats";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import { FetchBots } from "../../API";
import { confirmSignIn } from "../../utilities";

function Index() {
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 3000);
    }
  }, []);

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "2", href: "/dashboard/bot-active" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Active />
      {/* <TradeInfo /> */}
    </MainWrapper>
  );
}

export default Index;
