import React, { useState } from "react";
import MainWrapper from "../../MainWrapper";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Main from "./Main";

function Index() {
  const [showSideBar, setShowSideBar] = useState(false);
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  return (
    <MainWrapper>
      <Header setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "7", href: "/dashboard/select-bot" }}
      />
      <Main />
    </MainWrapper>
  );
}

export default Index;
