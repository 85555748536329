import React, { useEffect, useState } from "react";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import MainWrapper from "../MainWrapper";
import Chart from "./Chart";
import Main from "./Main";
import { useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import CenterModal from "../Modal";
import { ColorCard as ColorCardLarge } from "../Dashboard/BotActivate/CardLarge";

export const SPOT_BOTS = ["CB001", "CB004", "CB007", "CB011"];
function Index() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [details, setDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("main");
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error=()=>{};
  }
  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "5", href: "/dashboard/data-room" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

      {type === "chart" && <Chart details={details} />}

      {type === "main" && (
        <>
          {details.strategy?.name && type !== "chart" && (
            <CenterModal
              showModal={showModal}
              setShowModal={setShowModal}
              Data={
                <ColorCardLarge
                  bg={details.bg}
                  titleColor={details.titleColor}
                  lightCircle={details.lightCircle}
                  textLight={details.textLight}
                  textDark={details.textDark}
                  logo={details.logo}
                  denomination={details.denomination}
                  wallets={details.wallets}
                  strategy={details.strategy}
                  explore={
                    details.strategy.isAvailable ? "Explore" : "Not Available"
                  }
                  onClick={() => {
                    if (SPOT_BOTS.includes(details.strategy.name)) {
                    } else {
                      setShowModal(false);
                      setType("chart");
                    }
                  }}
                />
              }
            />
          )}

          <Main
            setDetails={setDetails}
            details={details}
            showModal={showModal}
            setShowModal={setShowModal}
            setType={setType}
          />
        </>
      )}
    </MainWrapper>
  );
}

export default Index;
